<template>
  <div class=" container-fluid container-fixed-lg bg-white app-table-container">
    <ConfirmModal ref="modal_delete_entry" :modal-header-title="$t('common.delete confirm title')"
                  :confirm-handle="deleteEntrySubmit">
    </ConfirmModal>
    <div class="card card-transparent">
      <div class="card-header search-container" v-if="search.searchAll || hasFiltersUi">
        <div class="search-content">
          <ValidationObserver v-slot="{ passes, valid, validated }">
            <label class="control-label input-label text-complete pb-3 ml-2"> 事業所管理 </label>
            <form novalidate autocomplete="off" @submit.prevent="passes(searchHandle)">
              <div v-if="search.searchAll && filters.length === 0 ||
              !search.searchAll && filters.length === 1" class="row">
                <div class="d-flex justify-content-start col-md-12">
                  <div class="w-25">
                    <AppInput input-style="normal" v-if="search.searchAll" v-model="pagination.search"/>
                    <template v-for="(filter, index) in filters">
                      <template v-if="filter.uiType === 'input'">
                        <AppInput :name="filter.label" :label="filter.label" input-style="normal"
                                  v-model="filters[index].value"/>
                      </template>
                      <template v-else-if="filter.uiType === 'select'">
                        <AppSelect :options="{containerCssClass: 'z-index-0'}" input-style="normal" :name="filter.name"
                                   :label="filter.label"
                                   :options-data="filter.options"
                                   v-model="filters[index].value"/>
                      </template>
                      <template v-else-if="filter.uiType === 'datepicker'">
                        <AppDatePicker input-style="normal" :name="filter.label" :label="filter.label"
                                       :rules="filter.rules ? filter.rules : []"
                                       v-model="filters[index].value"/>
                      </template>
                    </template>
                  </div>
                  <div class="m-t-auto m-b-15">
                    <button :disabled="!valid && validated" class="btn btn-complete m-l-10 m-r-10"
                            type="submit">
                      {{ $t('common.search') }}
                      <i
                        class="fa fa-search"></i>
                    </button>
                    <button v-if="search.reset"
                            @click="((typeof search.reset) === 'function') ? search.reset() : resetAllSearch()"
                            class="btn btn-default m-r-10" type="button">
                      {{ $t('common.reset') }}
                    </button>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="row">
                  <div v-if="search.searchAll" class="col-lg-3 col-md-4 col-sm-6">
                    <AppInput :label="$t('common.text search')" type="text" v-model="pagination.search"/>
                  </div>
                  <div v-for="(filter, index) in filters"
                       :class="[filter.boundaryClass, 'col-lg-3 col-md-4 col-sm-6']" v-if="filter.uiType">
                    <template v-if="filter.uiType === 'input'">
                      <AppInput :name="filter.name"
                                :label="filter.label"
                                input-style="normal"
                                label-class="text-complete"
                                v-model="filters[index].value"/>
                    </template>
                    <template v-else-if="filter.uiType === 'select'">
                      <AppSelect :options="{containerCssClass: 'z-index-0', placeholder: filter.label}"
                                 input-style="normal" :name="filter.name"
                                 :label="filter.label"
                                 label-class="text-complete"
                                 :options-data="filter.options"
                                 v-model="filters[index].value"/>
                    </template>
                    <template v-else-if="filter.uiType === 'multi_select'">
                      <AppSelectMulti :options="{...filter.ui_options, containerCssClass: 'z-index-0', placeholder: filter.label}"
                                      input-style="normal" :name="filter.name"
                                      :label="filter.label"
                                      label-class="text-complete"
                                      :onChange="filter.changeEvent ? filter.changeEvent : null"
                                      :options-data="filter.options"
                                      v-model="filters[index].value"/>
                    </template>
                    <template v-else-if="filter.uiType === 'datepicker'">
                      <AppDatePicker :rules="filter.rules ? filter.rules : ''"
                                     input-style="normal" :name="filter.name"
                                     :label="filter.label"
                                     label-class="text-complete"
                                     :options="filter.options ? filter.options : {}"
                                     v-model="filters[index].value"/>
                    </template>
                    <template v-else-if="filter.uiType === 'datepicker_from'">
                      <div class="row">
                        <div class="col-md-5-5">
                          <AppDatePicker :rules="filter.rules ? filter.rules : ''"
                                         input-style="normal" :name="filter.name"
                                         :label="filter.label"
                                         label-class="text-complete"
                                         v-model="filters[index].value"/>
                        </div>
                        <div class="col-md-1 p-0 m-0">
                          <div class="form-group app-form-group b-b-0">
                            <label class="input-label control-label p-0 p-r-5 visible-none">
                              none
                            </label>
                            <div class="form-group m-b-0 p-0 text-center" style="height: 50px; line-height: 50px">
                              ～
                            </div>
                          </div>
                        </div>
                        <div class="col-md-5-5">
                          <AppDatePicker
                            :rules="filters[index+1].rules ? filters[index+1].rules : ''"
                            input-style="normal"
                            label="none"
                            :name="filters[index+1].name"
                            label-class=" visible-none"
                            v-model="filters[index+1].value"/>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="m-t-10 row justify-content-center">
                  <button v-if="search.reset"
                          @click="((typeof search.reset) === 'function') ? search.reset() : resetAllSearch()"
                          class="btn btn-default m-r-10" type="button">
                    {{ $t('common.reset') }}
                  </button>
                  <button :disabled="!valid && validated" class="btn btn-complete m-r-10" type="submit">
                    {{ $t('common.search') }}
                  </button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
      <div class="card-header d-flex justify-content-between">
        <div class="card-title table-data-info">
          <div class="dataTables_info app-text" style="text-transform: lowercase" role="status" aria-live="polite">
            <template v-if="$cookies.get($config.APP_NAME + 'lang') == 'en'">
              <span style="text-transform: uppercase">S</span>howing {{ meta.from }} 〜 {{ meta.to }} of {{ meta.total }}
              records
            </template>
            <template v-else>
              全{{ meta.total }}件中 {{ meta.from }}件 〜 {{ meta.to }}件 を表示
            </template>
          </div>
        </div>
        <div class="d-flex">
          <div class="export-options-container">
            <div class="exportOptions">
              <div class="btn-group">
                <div>
                  <button v-if="actions.exportEntries && entries.length > 0" @click="exportListCsv()"
                          class="btn btn-complete app-text">
                    {{ $t('common.download csv') }}
                  </button>
                  <button v-if="actions.importEntries" @click="() => actions.importEntries()"
                          class="btn btn-default btn-export">
                    {{ $t('common.import csv') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="export-options-container ml-2">
          <div class="exportOptions">
            <div class="btn-group">
              <div>
                <button v-if="actions.createEntry" @click="() => actions.createEntry()" class="btn btn-create">
                  {{ $t('common.create') }}
                </button>
                <template v-if="actions.headers && actions.headers.length" v-for="action in actions.headers">
                  <template v-if="action.click">
                    <span v-html="action.contentHtml(entry)" @click="action.click()"></span>
                  </template>
                  <template v-else-if="action.routerLink">
                    <router-link :to="action.routerLink()">
                      <span v-html="action.contentHtml()"></span>
                    </router-link>
                  </template>
                  <template v-else>
                    <span v-html="action.contentHtml()"></span>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      <div class="card-body">
        <div class="dataTables_wrapper no-footer bg-white">
          <div class="table-responsive sm-m-b-15">
            <table :class="[entries.length ? '' : 'table-empty-data', 'table dataTable app-table no-footer']">
              <thead>
              <tr>
                <th class="text-center cell-fit-content">
                  No.
                </th>
                <template v-for="column in columns">
                  <th v-if="column.sortable" @click="toggleSortBy(column.name)"
                      :class="[
                    pagination.sortBy === column.name ? (pagination.descending ? 'sorting_desc' : 'sorting_asc') : 'sorting',
                     'text-center',
                     column.fitSize ? 'cell-fit-content' : ''
                     ]">
                    {{ column.label }}
                  </th>
                  <th v-else
                      :class="[ 'text-center', column.fitSize ? 'cell-fit-content' : '', column.class ? column.class : '']">
                    <template v-for="(line) of column.label.split('<line>')">
                      {{ line }} <br />
                    </template>
                  </th>
                </template>
                <th class="text-center cell-fit-content" v-if="actions.editEntry">{{
                  $t('offices.plans')
                  }}
                </th>
                <th class="text-center cell-fit-content th-edit" v-if="actions.editEntry">{{
                    $t('common.edit')
                  }}
                </th>
                <th class="text-center cell-fit-content th-delete" v-if="actions.deleteEntry || actions.deleteEntryCondition">{{
                    $t('common.delete')
                  }}
                </th>
                <th class="text-center cell-fit-content" v-for="action in actions.others">
                  {{ action.label }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="!entries.length">
                <td colspan="100">
                  <span v-if="loading">
                    {{ $t('common.loading') }}
                  </span>
                  <span v-else>
                    {{ $t('common.list empty') }}
                  </span>
                </td>
              </tr>
              <tr v-for="entry in entries">
                <td class="app-table-p v-align-middle text-center">
                  {{ entry.id }}
                </td>
                <td v-for="column in columns" :class="['v-align-middle', column.class]">
                  <p v-if="column.computedHtml" class="app-table-p app-cell-tooltip" :data-original-title="column.computed ? column.computed(entry) : _.get(entry, column.name)">
                    <span v-html="column.computedHtml(entry)"></span>
                  </p>
                  <p v-else :data-original-title="column.computed ? column.computed(entry) : _.get(entry, column.name)" data-toggle="tooltip" :title="_.get(entry, column.name)"
                     class="app-table-p app-cell-tooltip">
                    <span>{{ column.computed ? column.computed(entry) : _.get(entry, column.name) }}</span>
                  </p>
                </td>
                <td class="app-table-p v-align-middle text-center">
                  <button type="button" :class="['btn btn-complete']"
                          data-dismiss="modal" @click="redirectOfficePlans(entry.id)">
                    {{ $t('offices.plans') }}
                  </button>
                </td>
                <td class="v-align-middle" v-if="actions.editEntry">
                  <div @click="() => actions.editEntry(entry)"
                       class="btn-tool edit">
                  </div>
                </td>
                <td class="v-align-middle" v-if="actions.deleteEntry || actions.deleteEntryCondition">
                  <div
                    v-if="!actions.deleteEntryCondition || actions.deleteEntryCondition && actions.deleteEntryCondition(entry) "
                    @click="deleteEntry(entry)" class="btn-tool delete">
                  </div>
                </td>
                <td class="v-align-middle" v-for="action in actions.others">
                  <template v-if="action.showCondition ? action.showCondition(entry) : true">
                    <template v-if="action.click">
                      <span v-html="action.contentHtml(entry)" @click="action.click(entry)"></span>
                    </template>
                    <template v-else-if="action.routerLink">
                      <router-link :to="action.routerLink(entry)">
                        <span v-html="action.contentHtml(entry)"></span>
                      </router-link>
                    </template>
                    <template v-else>
                      <span v-html="action.contentHtml(entry)"></span>
                    </template>
                  </template>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="d-flex justify-content-between">
              <div class="dataTables_paginate app-paginate-container">
                <AppPaginate
                  v-show="parseInt(meta.last_page) > 1"
                  ref="paginate"
                  :page-count="parseInt(meta.last_page)"
                  :page-range="5"
                  :margin-pages="2"
                  :click-handler="clickPagination"
                  prev-text="<"
                  next-text=">"
                  breakViewClass="paginate-break"
                  prev-class="paginate-button-prev"
                  next-class="paginate-button-next"
                  :force-page="parseInt(pagination.currentPage)"
                  :hide-prev-next="true"
                  :page-class="'paginate-button'">
                </AppPaginate>
              </div>
              <div v-show="parseInt(meta.total) > 5"
                   class="d-flex justify-content-start padding-20 form-group dataTable_info_custom">
                <div class="m-r-5 m-t-5 perpage-label"> {{ $t('common.perpage') }}
                </div>
                <div class="form-group">
                  <select class="form-control" @change="changePerPage"
                          v-model="pagination.currentPerPage">
                    <option v-for="perpage in pagination.perPages" :value="perpage">
                      {{ perpage }}
                    </option>
                  </select></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const EVENT_PAGE_CHANGE = 'EVENT_PAGE_CHANGE';
const EVENT_SORT_BY = 'EVENT_SORT_BY';
const EVENT_SEARCH = 'EVENT_SEARCH';
const EVENT_PER_PAGE_CHANGE = 'EVENT_PER_PAGE_CHANGE';

export default {
  props: {
    settingColumns: Array,
    actions: Object,
    otherActions: Object,
    search: {
      type: Object,
      default: {
        searchAll: false,
        reset: false
      }
    },
    filters: [],
    settingApis: {},
    tableName: String,
  },
  data() {
    const columns = this.settingColumns.map(val => {
      return {
        sortable: false,
        editable: true,
        label: val.label === undefined ? val.name.charAt(0).toUpperCase() + val.name.slice(1) : val.label,
        ...val
      }
    })
    var cloneFilters = (this.filters);
    _.forEach(this.$route.query, (value, columnString) => {
      if (_.includes(columnString, 'filters.')) {
        let splited = columnString.split('.');
        let name = splited[1];
        let type = splited[2];
        _.forEach(cloneFilters, (filter, index) => {
          if (filter.name === name && filter.type === type) {
            if (filter.uiType === 'multi_select' && !_.isArray(value)) {
                value = [value]
              }
            cloneFilters[index] = {...cloneFilters[index], value: value}
          }
        })
      }
    })
    const {page, search, sortBy, sortType, perPage} = this.$route.query;
    return {
      chosen: "",
      chosen1: "",
      columns: columns,
      entriesRes: {},
      entries: [],
      loading: true,
      pagination: {
        currentPage: page ? page : 1,
        search: search ? search : '',
        sortBy: sortBy ? sortBy : null,
        descending: sortType === 'desc',
        currentPerPage: perPage ? perPage : 10,
        perPages: [5, 10, 25, 50, 100, 250, 500],
      },
      meta: {},
      entrySelectedDelete: {},
    }
  },
  watch: {
    "$route.query": {
      handler: async function (after, before) {
        if (!_.isEqual(after, before)) {
          this.getEntries()
        }
      },
      deep: true,
    },
  },
  computed: {
    hasFiltersUi() {
      var hasFiltersUi = false;
      _.forEach(this.filters, filter => {
        if (filter.uiType) {
          hasFiltersUi = true;
        }
      })
      return hasFiltersUi;
    },
  },
  mounted() {
    this.getEntries()
  },
  methods: {
    async exportEntries(name) {
      let filename = name + ".csv";
      var csv = [];
      var row = [];
      row.push('No.');

      _.forEach(this.columns, (value) => {
        row.push(value.label.replace('<line>', ''));
      });

      csv.push(row.join(","));
      const {currentPage, search, sortBy, descending, currentPerPage} = this.pagination;

      let params = {
        page: 1,
        perPage: 99999999999999999999999999999999999,
        search: search,
        sortBy: sortBy,
        sortType: descending ? 'desc' : 'asc',
      }

      const filtersClone = (this.filters)

      _.forEach(this.$route.query, (value, columnString) => {
        _.forEach(filtersClone, (filter) => {
          if (columnString.indexOf(filter.name) > 0 && columnString.indexOf(filter.type) > 0) {
            params[`filters[${filter.name}][${filter.type}]`] = value
          }
        })
      });

      let endpoint = this.settingApis.list.endpoint;
      let apiParams = this.settingApis.list.params;
      if (!apiParams) {
        apiParams = {}
      }
      const res = await this.$request.get(endpoint, {...params, ...apiParams});
      const all_row = res.data.data;

      _.forEach(all_row, (colum, index) => {
        let row = [];
        row.push((index + 1));
        _.forEach(this.columns, (column) => {
          if (column.name == 'status') {
            if (_.get(colum, column.name) == 0) {
              row.push(this.$t('offices.status_off'));
            } else {
              row.push(this.$t('offices.status_active'));
            }
          } else if (column.name == 'category_id') {
            let category_id = _.get(colum, column.name)
            if (category_id == 1) {
              row.push(this.$t('offices.text_hotel'));
            } else if (category_id == 2) {
              row.push(this.$t('offices.text_shop'));
            } else if (category_id == 3) {
              row.push(this.$t('offices.text_activity'));
            } else {
              row.push(this.$t('offices.text_other'));
            }
          } else {
            row.push(_.get(colum, column.name));
          }
        });

        csv.push(row.join(","));
      });

      this.downloadCSV(csv.join("\n"), filename);
    },
    downloadCSV(csv, filename)
    {
      var csvFile;
      var downloadLink;
      csvFile = new Blob([csv], {type:"text/csv"});
      downloadLink = document.createElement("a");
      downloadLink.download = filename;
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
    },
    clickPagination(selectPage) {
      if (selectPage !== this.pagination.currentPage) {
        this.setCurrentPage(selectPage)
        this.updateRouteQuery(EVENT_PAGE_CHANGE)
      }
    },
    changePerPage() {
      this.setCurrentPage(1)
      this.updateRouteQuery([EVENT_PAGE_CHANGE, EVENT_PER_PAGE_CHANGE])
    },
    setCurrentPage(page) {
      this.pagination.currentPage = page;
      if (this.$refs.paginate) {
        this.$refs.paginate.handlePageSelected(page);
      }
    },
    searchHandle() {
      this.setCurrentPage(1)
      this.updateRouteQuery([EVENT_PAGE_CHANGE, EVENT_SEARCH])
    },
    async getEntries() {
      await this.setSearchStateByRouteQuery()
      const {currentPage, search, sortBy, descending, currentPerPage} = this.pagination
      let params = {
        page: currentPage,
        perPage: currentPerPage,
        search: search,
        sortBy: sortBy,
        sortType: descending ? 'desc' : 'asc',
      }
      const filtersClone = (this.filters)
      _.forEach(filtersClone, (filter) => {
        if (filter.value && filter.value.toString().length && filter.value.toString() != 'none') {
          params[`filters[${filter.name}][${filter.type}]`] = filter.value
        }
      })
      let endpoint = this.settingApis.list.endpoint;
      let apiParams = this.settingApis.list.params;
      if (!apiParams) {
        apiParams = {}
      }
      const res = await this.$request.get(endpoint, {...params, ...apiParams});

      _.forEach(this.$route.query, (value, columnString) => {
        _.forEach(this.filters, (filter, index_ft) => {
          if (columnString.indexOf(filter.name) > 0) {
            this.filters[index_ft].value = value;
          }
        });
      });

      if (!res.hasErrors()) {
        this.entriesRes = _.cloneDeep(res);
        this.entries = res.data.data.map((entry, index) => {
          return {
            ...entry, no: (index + 1 + (this.pagination.currentPage - 1) * this.pagination.currentPerPage)
          }
        })
        this.meta = res.data.meta
        if (!this.meta.from) {
          this.meta.from = 0;
        }
        if (!this.meta.to) {
          this.meta.to = 0;
        }
      } else {
        this.$appNotice.error(res.data?.error_msg)
      }
      if (this.pagination.currentPage > 1 && this.entries.length === 0) {
        this.setCurrentPage(1)
        this.updateRouteQuery(EVENT_PAGE_CHANGE)
      }
      this.$nextTick(() => {
        $.each($('.app-cell-tooltip'), (index, el) => {
          if (el.offsetWidth < el.scrollWidth) {
            $(el).tooltip({boundary: 'window'})
          }
        })
      });
      this.loading = false;
    },
    async setSearchStateByRouteQuery() {
      const {page, search, sortBy, sortType, perPage} = this.$route.query;
      this.pagination = {
        currentPage: page ? page : 1,
        search: search ? search : '',
        sortBy: sortBy ? sortBy : null,
        descending: sortType === 'desc',
        currentPerPage: perPage ? perPage : 10,
        perPages: [5, 10, 25, 50, 100, 250, 500],
      }
      var newFilters = _.cloneDeep(this.filters)
      _.forEach(newFilters, (filter, key) => {
        newFilters[key] = {...filter, value: null}
      })
      _.forEach(this.$route.query, (value, columnString) => {
        if (_.includes(columnString, 'filters.')) {
          let splited = columnString.split('.');
          let name = splited[1];
          let type = splited[2];
          _.forEach(newFilters, (filter, key) => {
            if (filter.name === name && filter.type === type) {
              if (filter.uiType === 'multi_select' && !_.isArray(value)) {
                value = [value]
              }
              newFilters[key] = {...newFilters[key], value: value}
            }
          })
        }
      })
      await this.$emit('update:filters', newFilters)
    },
    async resetAllSearch() {
      this.chosen = this.chosen1 = "";
      if (Object.keys(this.$route.query).length === 0) {
        this.pagination.search = ''
        this.getEntries()
      } else {
        this.$router.push({name: this.$route.name, query: {}})
      }
    },
    toggleSortBy(columnName) {
      if (this.pagination.sortBy !== columnName) {
        this.pagination.sortBy = columnName
        this.pagination.descending = false;
      } else {
        if (!this.pagination.descending) {
          this.pagination.sortBy = columnName
          this.pagination.descending = true;
        } else {
          this.pagination.sortBy = null
        }
      }
      this.updateRouteQuery(EVENT_SORT_BY);
    },
    updateRouteQuery(events) {
      if (!_.isArray(events)) {
        events = [events]
      }
      var queryObj = _.clone(this.$route.query);
      var {currentPage, search, sortBy, descending, currentPerPage} = this.pagination
      _.forEach(events, event => {
        if (event === EVENT_PAGE_CHANGE) {
          if (currentPage > 1) {
            queryObj['page'] = currentPage
          } else {
            delete queryObj['page']
          }
        }
        if (event === EVENT_PER_PAGE_CHANGE) {
          if (parseInt(currentPerPage) !== 10) {
            queryObj['perPage'] = currentPerPage;
          } else {
            delete queryObj['perPage']
          }
        }
        if (event === EVENT_SORT_BY) {
          if (sortBy && sortBy.length) {
            queryObj['sortBy'] = sortBy;
          } else {
            delete queryObj['sortBy'];
          }
          if (sortBy && sortBy.length) {
            queryObj['sortType'] = descending ? 'desc' : 'asc';
          } else {
            delete queryObj['sortType'];
          }
        }
        if (event === EVENT_SEARCH) {
          if (search && search.length) {
            queryObj['search'] = search;
          } else {
            delete queryObj['search'];
          }
          const filters = (this.filters);
          _.forEach(filters, (filter) => {
            if (filter.value && filter.value.toString().length) {
              queryObj['filters.' + filter.name + '.' + filter.type] = filter.value
            } else {
              delete queryObj['filters.' + filter.name + '.' + filter.type];
            }
          })
        }
      })
      if (!_.isEqual(queryObj, this.$route.query)) {
        this.$router.push({query: queryObj})
      } else {
        this.getEntries()
      }
    },

    /* DELETE entry start */
    async deleteEntrySubmit() {
      const endpoint = this.settingApis.delete.endpoint;
      let params = this.settingApis.delete.params;
      if (!params) {
        params = {};
      }
      const res = await this.$request.delete(endpoint(this.entrySelectedDelete.id), params)
      if (!res.hasErrors()) {
        this.$appNotice.success(this.$t('common.msg delete ok'))
        this.$refs.modal_delete_entry.hide();
        this.getEntries()
        this.$emit('deletedEntry');
      } else {
        this.$appNotice.error(res.data?.error_msg)
      }
    },
    deleteEntry(entry) {
      this.entrySelectedDelete = entry;
      this.$refs.modal_delete_entry.show();
    },
    /* DELETE entry end */

    exportListCsv() {
      var params = {}
      _.forEach(this.filters, (filter) => {
        if (filter.value && filter.value.toString().length  && filter.value.toString() != 'none') {
          params[`filters[${filter.name}][${filter.type}]`] = filter.value
        }
      })
      this.$request.get(this.$consts.API.ADMIN.OFFICES_EXPORT, {...params}).then(res => {
        if (!res.hasErrors()) {
          this.downloadFromUrl(res.data.url)
        } else {
          this.$appNotice.error();
        }
      })
    },
    redirectOfficePlans(officeId) {
      let routeOfficePlans = this.$router.resolve({name: this.$consts.ROUTES.ADMIN.OFFICE_PLANS});
      window.open(routeOfficePlans.href + '?filters.office_id.multi_like=' + officeId, '_blank');
    }
  }
};
</script>

<style type="text/css">
  .default-input {
    height: 50px !important;
  }
</style>

<template>
  <div class="form-office">
    <Modal
      modal-class="slide-up modal_form"
      ref="modal_create_entry"
      size="lg"
      :on-hide="clearEntryToSave"
      :modal-header-title="tittle"
      @click="confirmClose"
    >
      <template v-slot:container>
        <ValidationObserver ref="form_create_entry" v-slot="{ passes, valid, validated }">
          <form novalidate autocomplete="off" @change="onchangeForm" v-on:submit.prevent="passes(saveEntry)">
            <div class="modal-body row">
              <div class="col-md-12"></div>
              <div class="col-md-6">
                <AppSelect
                  rules="required"
                  input-style="normal"
                  :label="$t('offices.text_cate')"
                  name="category_id"
                  :options-data="[
                    {id: 1, name: $t('offices.text_hotel')},
                    {id: 2, name: $t('offices.text_shop')},
                    {id: 3, name: $t('offices.text_activity')},
                    {id: 4, name: $t('offices.text_other')},
                  ]"
                  v-model="entryToSave.category_id"
                />
              </div>
              <div class="col-md-6">
                <AppInput
                  input-style="normal"
                  :label="$t('offices.company_name')"
                  type="string"
                  name="company_name"
                  v-model="entryToSave.company_name"
                />
              </div>

              <div class="col-md-6">
                <AppInput
                  rules="required"
                  input-style="normal"
                  :label="$t('offices.office_name')"
                  type="string"
                  name="office_name"
                  v-model="entryToSave.office_name"
                />
              </div>

              <div class="col-md-6">
                <AppInput
                  input-style="normal"
                  :label="$t('offices.homepage_url')"
                  type="url"
                  name="homepage_url"
                  rules="url"
                  v-model="entryToSave.homepage_url"
                />
              </div>

              <div class="col-md-6">
                <AppInput
                  rules="required|url"
                  input-style="normal"
                  :label="$t('offices.offices_map_url')"
                  type="url"
                  name="map_url"
                  v-model="entryToSave.map_url"
                />
              </div>

              <div class="col-md-6">
                <AppInput
                  rules="required|phone"
                  input-style="normal"
                  :label="$t('offices.offices_tel')"
                  type="number-length:11"
                  name="tel"
                  v-model="entryToSave.tel"
                />
              </div>

              <div class="col-md-6">
                <AppInput
                  rules="required|email"
                  input-style="normal"
                  :label="$t('offices.manager_email')"
                  type="email"
                  name="manager_email"
                  v-model="entryToSave.manager_email"
                />
              </div>

              <div class="col-md-6">
                <AppSelect
                  rules="required"
                  input-style="normal"
                  :label="$t('offices.status_office')"
                  name="status"
                  :options-data="status"
                  v-model="entryToSave.status"
                />
              </div>

              <div class="col-md-6">
                <AppSelect
                  rules="required"
                  input-style="normal"
                  :label="$t('offices.full_city_id')"
                  name="full_city_id"
                  :options-data="options_cities"
                  v-model="entryToSave.full_city_id"
                />
              </div>
              <div class="col-md-6"></div>

              <div class="col-md-6">
                <span>
                  <div class="form-group">
                    <label class="m-b-5 input-label control-label p-0 p-r-5">
                      {{ $t('offices.start_work') }}
                      <span class="color-active app-text-bold">＊</span>
                    </label>
                  </div>
                  <div class="form-group">
                    <VueTimepicker
                      @change="onchangeForm"
                      rules="required"
                      name="start_work"
                      hour-label="00"
                      minute-label="00"
                      v-model="entryToSave.start_work"
                      :hideClearButton="true"
                    />
                    <label
                      v-if="start_work"
                      class="error app-input-error-label-2"
                    >
                      {{ $t('offices.no_select_time') }}
                    </label>
                  </div>
                </span>
              </div>

              <div class="col-md-6">
                <span>
                  <div class="form-group">
                    <label class="m-b-5 input-label control-label p-0 p-r-5">
                      {{ $t('offices.end_work') }}
                      <span class="color-active app-text-bold">＊</span>
                    </label>
                  </div>

                  <div class="form-group">
                    <VueTimepicker
                      @change="onchangeForm"
                      rules="required"
                      input-style="normal"
                      name="end_work"
                      hour-label="00"
                      minute-label="00"
                      v-model="entryToSave.end_work"
                      :hideClearButton="true"
                    />
                    <label
                      v-if="end_work"
                      class="error app-input-error-label-2"
                    >
                      {{ $t('offices.no_select_time') }}
                    </label>
                  </div>
                </span>
              </div>
              <div class="col-md-12">
                <AppText
                  type="text"
                  input-style="normal"
                  name="description"
                  :label="$t('offices.offices_des')"
                  v-model="entryToSave.description"
                />
              </div>

              <div class="col-md-12">
                <div class="form-group">
                  <label class="m-b-5 input-label control-label p-0 p-r-5">
                    {{ $t('offices.offices_img') }}
                    <span class="color-active app-text-bold">＊</span>
                  </label>
                </div>

                <img
                  :height="officeImage.height + 'px'"
                  :width="officeImage.width + 'px'"
                  v-if="entryToSave.image != '' && entryToSave.image != null"
                  :src="entryToSave.image"
                />
                <div
                  v-if="entryToSave.image != ''"
                  class="btn-tool delete remove-image"
                  @click="removeImage()">
                </div>
                <input
                  ref="entryToSave_img"
                  accept="image/jpg,image/jpeg,image/png"
                  style="height:40px;"
                  class="form-control"
                  type="file"
                  name="image"
                  @change="uploadImage($event)"
                >

                <label
                  v-if="no_image"
                  class="error app-input-error-label-2"
                >
                  {{ $t('dialog.not_image') }}
                </label>
              </div>
            </div>

            <div class="modal-footer">
              <button
                @click="confirmClose"
                type="button"
                :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
              >
                {{ $t('common.cancel') }}
              </button>
              <button :disabled="!valid && validated" type="submit"
                      :class="['btn btn-cons inline btn-modal-submit', 'btn-complete']">
                {{ entryToSave.id ? $t('common.update_btn') : $t('common.create_btn') }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>

    <ConfirmModal
      ref="modal_delete_entry"
      :modal-header-title="$t('common.confirm_cancel_title')"
      :confirm-handle="deleteEntrySubmit">
    </ConfirmModal>

    <OfficeTable
      ref="table"
      :table-name="tableName"
      :setting-columns="columns"
      :actions="actions"
      :filters.sync="filters"
      :search="search"
      :setting-apis="apis"
      @deletedEntry="onDeletedEntry"
    />
  </div>
</template>
<script>
  import App from "@/App";
  import OfficeTable from '@components/_common/List/OfficeTable';
  var assert = require("assert");
  //const equalObjects = require('lodash');

  export default {
    data() {
      const image_default= {
        height:300,
        width:400
      }
      const image_default_square= {
        height:550,
        width:550
      }
      return {
        no_image: false,
        url: true,
        start_work: false,
        end_work: false,
        active: 0,
        tittle:"",
        tableName: this.$t('users.user list'),
        apis: {
          list: {
            endpoint: this.$consts.API.ADMIN.OFFICES_LIST,
          },
          delete: {
            endpoint: this.$consts.API.ADMIN.OFFICES_DELETE,
          }
        },
        search: {
          searchAll: false,
          reset: true,
        },
        options_cities: [],
        filters: [
          {
            name: 'company_name',
            type: 'multi_like',
            label: this.$t('offices.company_name'),
            uiType: 'multi_select',
            ui_options: {
              // tags: true,
              closeOnSelect: true,
            },
            boundaryClass: "col-lg-4 col-md-4 col-sm-6",
            options: [],
          },
          {
            name: 'office_name',
            type: 'multi_like',
            label: this.$t('offices.office_name'),
            uiType: 'multi_select',
            ui_options: {
              // tags: true,
              closeOnSelect: true,
            },
            boundaryClass: "col-lg-4 col-md-4 col-sm-6",
            options: [],
          },
          {
            name: 'status',
            type: 'equal',
            label: this.$t('offices.status'),
            uiType: 'select',
            options:[
              {
                id: "",
                value: "",
              },
              {
                id: 0,
                name: this.$t('offices.status_off')
              },
              {
                id: 1,
                name: this.$t('offices.status_active')
              },
              {
                id: "none",
                name: this.$t('common.select_all'),
              },
            ],
            boundaryClass: "col-lg-4 col-md-4 col-sm-6"
          },
        ],
        actions: {
          name: "offices",
          exportEntries: true,
          editEntry: this.editEntry,
          deleteEntry: true,
          createEntry: this.createEntry,
        },
        entryToSave: {
          status: 1,
          category_id: 1,
          start_work: "08:00",
          end_work: "17:00",
          tel: "",
          manager_email: "",
          image: "",
        },
        cloneSave:{},
        status: [
          {
            id: 0,
            name: this.$t('offices.status_off')
          },
          {
            id: 1,
            name: this.$t('offices.status_active')
          },
        ],
        columns: [
          {name: 'company_name', label: this.$t('offices.company_name')},
          {name: 'office_name', label: this.$t('offices.office_name'), class: 'min-w-180'},
          {
            name: 'homepage_url',
            label: this.$t('offices.homepage_url'),
            class: 'offices-width-180',
            computedHtml: (entry) => {
              let url = this.displayLink(entry.homepage_url);

              if (url != undefined && url != '') {
                return `<a target='_blank' href='${url}' style='color: #007bff !important;'"> ${entry.homepage_url} </a>`
              }
            }
          },
          {
            name: 'map_url',
            label: this.$t('offices.offices_map_url'),
            computedHtml: (entry) => {
              let url = this.displayLink(entry.map_url);

              if (url != undefined && url != '') {
                return `<a target='_blank' href='${url}' style='color: #007bff !important;'"> ${entry.map_url} </a>`;
              }
            }
          },
          {name: 'tel', label: this.$t('offices.offices_tel')},
          {name: 'manager_email', label: this.$t('offices.manager_email_tb'), class: 'min-w-180'},
          {name: 'start_work', label: this.$t('offices.start_work')},
          {name: 'end_work', label: this.$t('offices.end_work')},
          {name: 'description', label: this.$t('offices.offices_des'), class: 'min-w-180'},
          {
            label: this.$t('offices.offices_img_tb'),
            name: 'image',
            class: "offices-width-180",
            computedHtml: entry => {
              return "<img src='"+entry.image+"' style='width:80px'>"
            },
          },
          {
            name: 'status',
            label: this.$t('offices.status_tb'),
            class: "offices-width-180",
            computedHtml: entry => {
              if (entry.status) {
                return '<span class="alert alert-info">' + this.$t('offices.status_active') + '</span>'
              } else {
                return '<span class="alert alert-disable">' + this.$t('offices.status_off') + '</span>'
              }
            },
          },
          {
            label: this.$t('offices.text_cate_tb'),
            name: 'category_id',
            class: "offices-width-180",
            computedHtml: entry => {
              if (entry.category_id == 1) {
                return '<span>' + this.$t('offices.text_hotel') + '</span>';
              } else if (entry.category_id == 2) {
                return '<span>' + this.$t('offices.text_shop') + '</span>';
              } else if (entry.category_id == 3) {
                return '<span>' + this.$t('offices.text_activity') + '</span>';
              } else {
                return '<span>' + this.$t('offices.text_other') + '</span>';
              }
            },
          },
        ],
        image_default: _.cloneDeep(image_default),
        image_default_square: _.cloneDeep(image_default_square),
        image:{
          height:'',
          width:''
        },
        officeImage: _.cloneDeep(image_default),
      }
    },
    components: {OfficeTable},
    mounted() {
      this.getDataSelect();
      this.getFullCities();
    },
    methods: {
      getDataSelect()
      {
        this.$request.get(this.$consts.API.ADMIN.OFFICES_LIST_SELECT_COMPANY_NAME).then(res => {
          if(!res.hasErrors()) {
            this.filters[0].options = res.data;
          }
        });

        this.$request.get(this.$consts.API.ADMIN.OFFICES_LIST_SELECT_NAME).then(res => {
          if(!res.hasErrors()) {
            this.filters[1].options = res.data;
          }
        });
      },
      async getFullCities() {
        const res = await this.$request.get(this.$consts.API.ADMIN.TOUR_FULL_CITIES, {});
        if (!res.hasErrors()) {
          this.options_cities = res.data.data;
        }
      },
      displayLink(link)
      {
        if (link == null) {
          return "";
        }

        if (link == null || link == '' || link == undefined) {
          return;
        }

        if (link.indexOf('http') < 0) {
          link = 'http://' + link;
        }

        return link;
      },
      createEntry() {
        this.tittle = this.$t('offices.create_offices');
        this.entryToSave = {
          status: 1,
          category_id: 1,
          start_work: "08:00",
          end_work: "17:00",
          tel: "",
          manager_email: "",
          image: "",
        };

        this.cloneSave = _.cloneDeep(this.entryToSave);
        this.$refs.entryToSave_img.value = null;
        this.$refs.form_create_entry.reset();
        this.$refs.modal_create_entry.show();
      },
      editEntry (entry)
      {
        this.tittle = this.$t('offices.edit_offices');
        this.entryToSave = _.cloneDeep(entry);
        this.cloneSave = _.cloneDeep(this.entryToSave);
        this.$refs.modal_create_entry.show();
        this.checkImageSize()
      },
      confirmClose()
      {
        this.$refs.modal_delete_entry.show();
        // if (!_.isEqual(this.cloneSave, this.entryToSave)) {
        //   this.$refs.modal_delete_entry.show();
        // } else {
        //   this.$refs.modal_create_entry.hide();
        // }
      },
      onchangeForm()
      {
        this.active = 1;
      },
      clearEntryToSave()
      {
        this.no_image = false;
        this.start_work = false;
        this.end_work = false;
        this.$refs.entryToSave_img.value = null;
        this.$refs.form_create_entry.reset();
        this.$refs.table.getEntries();
      },
      deleteEntrySubmit()
      {
        this.$refs.table.getEntries();
        this.$refs.modal_delete_entry.hide();
        this.$refs.modal_create_entry.hide();
        this.active = 0;
      },
      fomatTime(time)
      {
        let time_new = time.toString().split(':');
        return Number(time_new[0]) + (Number(time_new[1]) / 60);
      },
      async saveEntry()
      {
        let email = this.entryToSave.manager_email;
        let phone = this.entryToSave.tel;
        let start_work = this.fomatTime(this.entryToSave.start_work);
        let end_work = this.fomatTime(this.entryToSave.end_work);

        if (end_work - start_work < 0) {
          this.$appNotice.error(this.$t('dialog.star_end_work'));
          return;
        }

        if (phone.length > 0) {
          this.entryToSave.tel = phone = phone.replace(/[^\d]/g, '');

          if (phone.length < 10 || phone.length > 11) {
            this.$appNotice.error(this.$t('dialog.not_phone'));
            return;
          }
        }

        if (email.indexOf('@') < 0 && email.length > 0) {
          this.$appNotice.error(this.$t('dialog.not_email'));
          return;
        }

        if (this.entryToSave.start_work == "" ||
          this.entryToSave.start_work.indexOf('HH') == 0 ||
          this.entryToSave.start_work.indexOf('mm') > 0
        ) {
          this.start_work = true;
        } else {
          this.start_work = false;
        }

        if (this.entryToSave.end_work == "" ||
          this.entryToSave.end_work.indexOf('HH') == 0 ||
          this.entryToSave.end_work.indexOf('mm') > 0
         ) {
          this.end_work = true;
          return;
        } else {
          this.end_work = false;
        }

        if (this.entryToSave.image == "" || this.entryToSave.image == null) {
          this.no_image = true;
          return;
        }

        this.active = 0;

        if (this.entryToSave.id) {
          const res = await this.$request.patch(this.$consts.API.ADMIN.OFFICES_SAVE + '/' + this.entryToSave.id, this.entryToSave);

          if (res.hasErrors()) {
            this.$appNotice.error( res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
          } else {
            this.$appNotice.success(this.$t('common.msg update ok'))
            this.$refs.modal_create_entry.hide();
            this.$refs.table.getEntries();
            this.getDataSelect();
          }
        } else {
          const res = await this.$request.post(this.$consts.API.ADMIN.OFFICES_SAVE, this.entryToSave);

          if (res.hasErrors()) {
            this.$appNotice.error( res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
          } else {
            this.$appNotice.success(this.$t('common.msg create ok'))
            this.$refs.modal_create_entry.hide();
            this.$refs.table.getEntries();
            this.getDataSelect();
          }
        }
      },
      async uploadImage(event)
      {
        const image = event.target.files[0];

        if (this.checkImage(image)) {
          this.$appNotice.error(this.$t('dialog.image_type'));
          this.$refs.entryToSave_img.value = null;

          return;
        }

        if ((image.size / 1048576) > 2) {
          this.$appNotice.error(this.$t('dialog.image_max', {size: '2MB'}));
          this.$refs.entryToSave_img.value = null;

          return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(image);

        reader.onload = e =>{
          this.uploadImage1(e.target.result)
          let img = new Image();
          img.onload = () => {
            this.image.width = img.width;
            this.image.height = img.height;
            this.changeSize(img.width, img.height)
          }
          img.src = e.target.result;
        };
      },
      async changeSize(width, height) {
        this.officeImage = _.cloneDeep(this.image_default)
        if (width === height) {
          this.officeImage = _.cloneDeep(this.image_default_square)
        }
      },

      async uploadImage1(file)
      {
        const res = await this.$request.post(this.$consts.API.ADMIN.UPLOAD_IMAGE_OFFICES, {
          image: file,
          path: "offices/",
        });

        this.no_image = false;
        this.entryToSave.image = res.data.data;
      },

      removeImage(){
        this.entryToSave.image = '';
        this.$refs.entryToSave_img.value = null
      },
      onDeletedEntry() {
        this.getDataSelect();
      },
      checkImageSize() {
        this.checkSize(this.entryToSave.image)
      },
      checkSize(source) {
        let img = new Image();
        img.onload = () => {
          this.image.width = img.width;
          this.image.height = img.height;
          this.changeSize(img.width, img.height)
        }
        img.src = source;
      },
    }
  }
</script>
<style>
  .default-input {
    height: 42px;
  }
  .form-office .vue__time-picker, .form-office .vue__time-picker input.display-time {
    width: 100% !important;
  }
  .form-office .vue__time-picker .dropdown, .form-office .vue__time-picker-dropdow {
    width: 100% !important;
  }
  .form-office .vue__time-picker .dropdown .select-list, .form-office .vue__time-picker-dropdown .select-list{
    width: 100%;
  }
  .form-office .modal_form .close {
    display: none;
  }
  .form-office .offices-width-180 {
    min-width: 180px !important;
  }
  .form-office .cell-fit-content {
    min-width: 10px;
  }
  .select2-container {
    z-index: 9999;
  }
</style>
